/* You can add global styles to this file, and also import other style files */
@use '@adnova/jf-ng-components/lib';
@import 'css-customized/mat-buttons.scss';
@import 'css-customized/mat-form.scss';
@import 'css-customized/headlines.scss';

@font-face {
  font-family: OpenSans;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  src: url(../assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(../assets/fonts/OpenSans-BoldItalic.ttf) format('truetype');
}

@layer material {
  * {
    font-family: OpenSans, Arial;
  }
}

@include lib.app-preset-skeleton;

.shadow {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.mat-raised-button {
  font-size: 12px;
}

// INFO: Disabled Farbe des Form-Fields
.mat-form-field.mat-form-field-disabled .mat-form-field-outline div {
  background: #FAFAFA;
}

// INFO: Disabled Farbe der Outline des Form-Fields
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: #FAFAFA;
}

// INFO: Enabled Farbe der Outline des Form-Fields
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #999999;
}

// INFO:
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
.mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float
.mat-input-server:focus .mat-form-field-label-wrapper .mat-form-field-label {
  font-weight: bold;
}

mat-form-field {
  button {
    &.mat-icon-button:enabled[mat-icon-button] {
      color: currentColor !important;
    }
  }
}

button {

  &.mat-button,
  &.mat-raised-button,
  &.mat-flat-button {
    box-shadow: none !important;
  }

  &.mat-button:enabled,
  &.mat-raised-button:enabled,
  &.mat-flat-button:enabled {
    &:hover,
    &:focus {
      background-color: var(--default-hover) !important;

      &[color="accent"] {
        background-color: var(--accent-hover) !important;
      }

      &[color="primary"] {
        background-color: var(--primary-hover) !important;
      }
    }
  }

  &.mat-stroked-button:enabled,
  &.mat-icon-button:enabled {
    &[mat-icon-button] {
      color: var(--accent) !important;
    }

    &:hover,
    &:focus {
      border-color: var(--accent-hover) !important;
      color: var(--accent-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}

mat-paginator {
  font-size: 12px;
  background: transparent;

  .mat-paginator-container {
    margin: 0;
    padding: 0;
    min-height: 45px;
    justify-content: flex-start;
  }

  .mat-paginator-page-size {
    display: none !important;
  }

  // Paginator Buttons
  span.mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Navigation First
  .mat-paginator-navigation-first {
    order: 1;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Navigation Previous
  .mat-paginator-navigation-previous {
    order: 2;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Range Label
  .mat-paginator-range-label {
    width: 80px;
    color: #414141;
    order: 3;
    text-align: center;
  }

  // Navigation Next
  .mat-paginator-navigation-next {
    order: 4;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }

  // Navigation Last
  .mat-paginator-navigation-last {
    order: 5;

    .mat-paginator-icon path {
      fill: #1A1A1A;
    }

    &:disabled .mat-paginator-icon path {
      fill: #D0D0D0;
    }
  }
}
