@forward './styles/global';
@forward './styles/material';
@use 'material-icons/iconfont/material-icons.css';

@use '../node_modules/@adnova/jf-ng-components/assets/styles/config' as *;
@forward '../node_modules/@adnova/jf-ng-components/assets/styles/base/typo';


@layer bootstrap, material, base;
@layer bootstrap {
  @import 'bootstrap/scss/bootstrap';
}